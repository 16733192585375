import React from 'react'
import { Grid } from '@material-ui/core'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { WrapperSection } from '../../wrappers'
import SideLine from '../../elements/SideLine/SideLine'

import {
  sBlogWrapper,
  sBlog,
  sBlogTheme,
  sImage,
  sTitle,
} from '../../pages/BlogsSearch/BlogsSearch'

const FilesMinis = ({ data, theme }) => {
  const { title, slug_section, files } = data
  return (
    <WrapperSection
      noPaddingTop
      id={slug_section}
      outside={<SideLine theme={theme}>{title}</SideLine>}>
      <Grid container>
        {files?.map((files, id) => (
          <Grid xs={12} sm={6} md={4} item css={sBlogWrapper} key={id}>
            <Link
              to={`${files?.file_url}`}
              key={id}
              css={[sBlog, sBlogTheme(theme), { height: 'max-content' }]}>
              <div css={sImage}>
                {files.image?.extension === 'svg' ? (
                  <img
                    src={files.image?.publicURL}
                    alt={files.image?.name + 'RDB'}
                    css={[sImage.image, { transform: 'scale(0.6)' }]}
                  />
                ) : (
                  files.image?.childImageSharp?.fluid && (
                    <Img
                      style={sImage.image}
                      fluid={files.image.childImageSharp.fluid}
                    />
                  )
                )}
              </div>
              <div css={sTitle}>{files.title}</div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </WrapperSection>
  )
}

// const sContainer = {
//   margin: '0 auto',
//   maxWidth: globals.maxWidth,
//   [s.lg_1280]: {
//     padding: '0 2rem',
//   },
//   marginBottom: '4rem',
//   [s.md]: {
//     padding: '0 2rem',
//   },
//   [s.sm_down]: {
//     padding: 0,
//   },
// }

export default FilesMinis
